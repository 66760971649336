/* eslint-disable no-empty-pattern */
import { BaseQueryFn, createApi } from '@reduxjs/toolkit/query/react';
import { AxiosClient } from '@src/app/AxiosClient';
import appConfig from '@src/configs/appConfig';
import { AxiosRequestConfig, AxiosError } from 'axios';

interface Meta {
  locationHeader: string | undefined;
}

export type HttpError = {
  data: { code: string; reason: string };
  status?: number;
};

export const axiosBaseQuery =
  (
    { baseUrl, useAuthToken }: { baseUrl: string; useAuthToken: boolean } = {
      baseUrl: '',
      useAuthToken: false,
    }
  ): BaseQueryFn<
    {
      url: string;
      method: AxiosRequestConfig['method'];
      data?: AxiosRequestConfig['data'];
      params?: AxiosRequestConfig['params'];
    },
    unknown,
    unknown,
    unknown,
    Meta
  > =>
  async ({ url, method, data, params }) => {
    try {
      const axios = new AxiosClient({ baseUrl, useAuthToken });
      const result = await axios.client({
        url: baseUrl + url,
        method,
        data,
        params,
      });
      return {
        data: result.data,
        meta: { locationHeader: result.headers.location },
      };
    } catch (axiosError) {
      const err = axiosError as AxiosError;
      return {
        error: {
          status: err.response?.status,
          data: err.response?.data || err.message,
        },
      };
    }
  };

// Define a service using a base URL and expected endpoints
export const api = createApi({
  // reducerPath: 'api',
  baseQuery: axiosBaseQuery({
    baseUrl: appConfig.urls.baseUrl,
    useAuthToken: true,
  }),
  tagTypes: [
    'appointment',
    'simple-appointment',
    'appointment-history',
    'upcoming-appointments',
    'account',
    'availability',
    'customer-cards',
    'order',
    'booking-order',
    'orders',
    'gift-card',
  ],
  endpoints: () => ({}),
});

export const apiNoAuth = createApi({
  reducerPath: 'apiNoAuth',
  baseQuery: axiosBaseQuery({
    baseUrl: appConfig.urls.baseUrl,
    useAuthToken: false,
  }),
  tagTypes: [
    'availability',
    'location-forecast',
    'location',
    'locations',
    'location-hours',
    'location-service-categories',
    'operations-exceptions',
    'businesses',
    'business',
    'services',
    'staff-add-ons',
    'treatment-add-ons',
  ],
  endpoints: () => ({}),
});

export const apiExample = createApi({
  reducerPath: 'apiExample',
  baseQuery: axiosBaseQuery({
    baseUrl: 'https://datausa.io',
    useAuthToken: false,
  }),
  tagTypes: ['example-report-data'],
  endpoints: () => ({}),
});

export const {} = api;
export const {} = apiNoAuth;
export const {} = apiExample;
