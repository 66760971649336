import { createSlice } from '@reduxjs/toolkit';
import { IGiftCardOrderDetails, IPurchaseGiftCardForm } from './types';

interface GiftCardPurchaseState {
  giftCardShoppingCart: IPurchaseGiftCardForm[];
  giftCardOrder: IGiftCardOrderDetails | null;
}

const initialState: GiftCardPurchaseState = {
  giftCardShoppingCart: [],
  giftCardOrder: null,
};

export const GiftCardPurchaseSlice = createSlice({
  name: 'gift-card-purchase',
  initialState,
  reducers: {
    addToCart: (
      state: GiftCardPurchaseState,
      action: { payload: IPurchaseGiftCardForm }
    ) => {
      state.giftCardShoppingCart = [
        ...state.giftCardShoppingCart,
        action.payload,
      ];
    },
    setGiftCardOrder: (
      state: GiftCardPurchaseState,
      action: { payload: IGiftCardOrderDetails | null }
    ) => {
      state.giftCardOrder = action.payload;
    },
    removeFromCart: (
      state: GiftCardPurchaseState,
      action: { payload: string }
    ) => {
      state.giftCardShoppingCart = [
        ...state.giftCardShoppingCart.filter((gc) => gc.id !== action.payload),
      ];
    },
    clearCart: (state: GiftCardPurchaseState) => {
      state.giftCardShoppingCart = [];
    },
  },
  extraReducers: () => {},
});

export const { addToCart, setGiftCardOrder, removeFromCart, clearCart } =
  GiftCardPurchaseSlice.actions;

export default GiftCardPurchaseSlice.reducer;
